<template>
  <div>
    <b-form-group
      :label="'Recurso Bibliográfico'"
      label-for="teaching-bibliographic-resource-select"
      label-cols="0"
      label-cols-sm="3"
    >
      <b-form-select
        id="teaching-bibliographic-resource-select"
        size="sm"
        v-model="$v.epm_eva_biblio_resource.bibliographic_resource.$model"
        :state="validateState('bibliographic_resource')"
        class="select-form"
        aria-describedby="input-bibliographic_resource-live-feedback"
      >
        <b-form-select-option-group
          v-for="item in filterBibliographicResource"
          :key="item.id"
          :label="item.name"
        >
          <b-form-select-option
            class="select-option-micro"
            v-for="ecm in item.options"
            :value="ecm.id"
            :key="ecm.id"
          >
            {{ truncateText(ecm.description, 130) }}
          </b-form-select-option>
        </b-form-select-option-group>
      </b-form-select>
      <b-form-invalid-feedback id="input-bibliographic_resource-live-feedback"
        >Este campo es obligatorio.</b-form-invalid-feedback
      >
    </b-form-group>
    <b-form-group
      v-if="institution && institution.internal_use_id == 'duoc_uc'"
      :label="'Tipo'"
      label-for="type-select"
      label-cols="0"
      label-cols-sm="3"
    >
      <b-form-select
        id="type-select"
        size="sm"
        v-model="$v.epm_eva_biblio_resource.is_mandatory.$model"
        :state="validateState('is_mandatory')"
        class="select-form"
        aria-describedby="input-is_mandatory-live-feedback"
        text-field="value"
        value-field="id"
        :options="options"
      >
      </b-form-select>
      <b-form-invalid-feedback id="input-is_mandatory-live-feedback"
        >Este campo es obligatorio.</b-form-invalid-feedback
      >
    </b-form-group>
    <b-form-group
      label="Detalles"
      label-for="input-details"
      label-cols="4"
      label-cols-sm="3"
      class="label"
    >
      <b-form-textarea
        id="input-details"
        name="input-details"
        v-model="$v.epm_eva_biblio_resource.details.$model"
        :state="validateState('details')"
        aria-describedby="input-details-live-feedback"
        size="md"
        rows="3"
        max-rows="8"
      ></b-form-textarea>
      <b-form-invalid-feedback id="input-details-live-feedback"
        >Este campo debe tener al menos 5 caracteres.</b-form-invalid-feedback
      >
    </b-form-group>
    <div class="row">
      <div class="col" style="text-align: right">
        <b-button class="m-0" size="sm" @click="save">Guardar</b-button>
      </div>
    </div>
  </div>
</template>

<script>
import { generateUniqueId } from "@/utils/utils";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import { toast } from "@/utils/utils";
import { mapGetters } from "vuex";

export default {
  name: "EPMEvaluationBibliographicResourceForm",
  mixins: [validationMixin],
  props: {
    epm_evaluation_id: {
      type: Number,
      required: true,
    },
    EPMEvaBibliResource: {
      type: Object,
      default: function () {
        return {
          id: generateUniqueId(),
          egress_profile_matter_evaluation: this.epm_evaluation_id,
          bibliographic_resource: null,
          details: "",
          is_mandatory: true,
        };
      },
    },
    bibliographic_resources: {
      type: Array,
    },
    bibliographic_resource_types: {
      type: Array,
    },
  },
  data() {
    return {
      epm_eva_biblio_resource: { ...this.EPMEvaBibliResource },
      options: [
        { id: true, value: "Bibliografía Obligatoria" },
        { id: false, value: "Bibliografía Complementaria" },
      ],
    };
  },
  validations: {
    epm_eva_biblio_resource: {
      bibliographic_resource: {
        required,
      },
      details: {},
      is_mandatory: { required },
    },
  },
  computed: {
    ...mapGetters({
      institution: "getInstitution",
    }),
    filterBibliographicResource() {
      let list = [];
      this.bibliographic_resource_types.forEach((resource_type) => {
        let resource = null;
        resource = this.bibliographic_resources
          .filter((x) => x.type == resource_type.id)
          .sort(function (a, b) {
            if (a.order > b.order) return 1;
            if (a.order < b.order) return -1;
          });
        if (resource.length > 0)
          list.push({
            id: resource_type.id,
            name: resource_type.name,
            options: resource.sort(function (a, b) {
              if (a.id > b.id) return 1;
              if (a.id < b.id) return -1;
            }),
          });
      });
      return list;
    },
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.epm_eva_biblio_resource[name];
      return $dirty ? !$error : null;
    },
    save() {
      this.$v.epm_eva_biblio_resource.$touch();
      if (this.$v.epm_eva_biblio_resource.$anyError) {
        return;
      }
      if (isNaN(this.epm_eva_biblio_resource.id)) {
        this.createEPMEvaBiblioResource();
      } else this.updateEPMEvaBiblioResource();
    },
    truncateText(html, maxLength) {
      const convert_text = this.$htmlToPlainText(html);
      if (convert_text.length > maxLength) {
        return convert_text.slice(0, maxLength) + "...";
      }
      return convert_text;
    },
    createEPMEvaBiblioResource() {
      this.$restful
        .Post(
          `/teaching/epm-evaluation-bibliographic-resource/`,
          this.epm_eva_biblio_resource
        )
        .then((response) => {
          toast("Recurso Bibliográfico creado.");
          this.$emit("created", response);
        });
    },
    updateEPMEvaBiblioResource() {
      this.$restful
        .Patch(
          `/teaching/epm-evaluation-bibliographic-resource/${this.epm_eva_biblio_resource.id}/`,
          this.epm_eva_biblio_resource
        )
        .then((response) => {
          toast("Recurso Bibliográfico actualizado.");
          this.$emit("updated", response);
        });
    },
  },
  created() {},
};
</script>

<style scoped>
.select-form {
  display: block !important;
}
.select-option-macro,
.select-option-micro {
  overflow-wrap: break-word;
  white-space: pre-wrap;
  padding: 0 0.25em;
  margin-left: 10px;
}
.select-option-micro {
  text-indent: -16px;
}
</style>